/** @jsx jsx */
import { graphql } from "gatsby";
import { StoreBadges } from "gatsby-theme-store-badges";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetMobileApps" }>;
}

const MobileAppsWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <StoreBadges
        displayIOSLink={!!widget.mobileAppsShape?.displayIOSLink}
        displayAndroidLink={!!widget.mobileAppsShape?.displayAndroidLink}
        buttonSize={widget.mobileAppsShape?.buttonSize || undefined}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetMobileApps on WidgetMobileApps {
    id
    __typename
    mobileAppsShape {
      displayIOSLink
      displayAndroidLink
      buttonSize
    }
  }
`;

export default memo(MobileAppsWidget);
